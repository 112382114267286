<template>
  <div class="upload-void-cheque">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Add new bank</div>
      <div class="main has-background-white">
        <div class="has-text-weight-semibold box-title has-text-centered">Upload void cheque</div>
        <div class="box-subtitle has-text-centered">Upload a void cheque to your bank account. This can be either a screenshot, image, or downloaded void cheque from online banking.</div>
        <div class="upload-section">
          <UploadButton @change="uploadCheque"></UploadButton>
          <img class="preview-image" :src="previewUrl" alt="preview" v-if="previewUrl">
          <div class="preview-frame" v-else></div>
          <div class="upload-hint has-text-centered has-text-lavender">SSL Encryption: Data is transmitted over a 256-bit connection using SSL encryption 🔒</div>
        </div>
      </div>
      <BottomButtons
        :showSteps="false"
        :nextDisabled="!previewUrl"
        @goNext="toAccountInfoPage"
        @goBack="goBack"
      ></BottomButtons>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import UploadButton from '@components/upload/button.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { initIndexedDB } from '@utils/common-methods/initIndexedDB'
import { addPdfJs } from '@utils/common-methods/common'

export default {
  data() {
    return {
      chequeFile: null,
      fileType: undefined,
      previewUrl: '',
    }
  },
  computed: {
    previewBackground() {
      return `#F0EEF8 url(${this.previewUrl}) center center/cover no-repeat`
    },
  },
  components: {
    NavBack,
    MenuAndLogo,
    UploadButton,
    BottomButtons,
  },
  mounted() {
    addPdfJs()
    this.showPreview()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    toAccountInfoPage() {
      const bank = JSON.parse(localStorage.getItem('bankAccount'))
      Object.assign(bank, {
        type: 'manual',
        isPersonalAccount: true,
      })
      localStorage.setItem('bankAccount', JSON.stringify(bank))
      this.$router.push('/wallet/accounts/manual/accountInfo')
    },
    uploadCheque(event) {
      this.chequeFile = event.target.files[0]
      this.fileType = event.target.files[0].type
      this.storeUploadedFileInIndexedDb(event.target.files[0])
    },
    storeUploadedFileInIndexedDb(file) {
      this.convertFileToArrayBuffer(file).then((arrayBuffer) => {
        this.storeArrayBufferInIndexedDb(arrayBuffer)
      })
    },
    convertFileToArrayBuffer(file) {
      /* eslint-disable no-new */
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.readAsArrayBuffer(file)
      })
    },
    storeArrayBufferInIndexedDb(arrayBuffer) {
      const objectStore = 'voidCheque'
      initIndexedDB('bankAccount', objectStore, (db) => {
        const transaction = db.transaction([objectStore], 'readwrite')
        transaction.objectStore(objectStore).put(arrayBuffer, 'file').onsuccess = () => {
          transaction.objectStore(objectStore).put(this.fileType, 'fileType').onsuccess = () => {
            this.showPreview()
          }
        }
      })
    },
    showPreview() {
      const objectStore = 'voidCheque'
      initIndexedDB('bankAccount', objectStore, (db) => {
        const transaction = db.transaction([objectStore])
        const request = transaction.objectStore(objectStore).get('fileType')
        request.onsuccess = () => {
          this.fileType = request.result
          if (!this.fileType) return
          const request2 = transaction.objectStore(objectStore).get('file')
          request2.onsuccess = () => {
            if (this.fileType.startsWith('image')) this.previewImage(request2.result)
            else this.previewPdf(request2.result)
          }
        }
      })
    },
    previewImage(arrayBuffer) {
      if (this.previewUrl) URL.revokeObjectURL(this.previewUrl)

      const blob = new Blob([arrayBuffer], { type: this.fileType })
      const fileName = `void-cheque.${this.fileType.split('/')[1]}`
      this.chequeFile = new File([blob], fileName, { type: this.fileType })
      this.previewUrl = URL.createObjectURL(this.chequeFile)
    },
    previewPdf(arrayBuffer) {
      const pdfjsLib = window['pdfjs-dist/build/pdf']
      pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js'

      const typedArray = new Uint8Array(arrayBuffer)
      const loadingTask = pdfjsLib.getDocument(typedArray)

      loadingTask.promise.then((pdf) => {
        const pageNumber = 1
        pdf.getPage(pageNumber).then((page) => {
          const scale = 1.5
          const viewport = page.getViewport({ scale: scale })

          const canvas = document.createElement('canvas')
          const context = canvas.getContext('2d')
          canvas.height = viewport.height
          canvas.width = viewport.width

          const renderContext = {
            canvasContext: context,
            viewport: viewport
          }
          const renderTask = page.render(renderContext)
          renderTask.promise.then(() => {
            this.previewUrl = canvas.toDataURL()
          })
        })
      }, (reason) => {
        console.error(reason)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.upload-void-cheque {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-bottom: 80px;
  min-height: 100vh;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      min-height: 426px;
      border-radius: 12px;
      box-shadow: 0px 20px 40px rgba(215, 145, 51, 0.15);
      margin-bottom: 27px;
      padding: 0 14px 20px;
      .box-title {
        font-size: 16px;
        padding-top: 24px;
      }
      .box-subtitle {
        padding: 8px 0 40px;
        font-size: 16px;
        line-height: 19px;
      }
      .upload-section {
        padding: 0 38px;
        .preview-image {
          width: 100%;
          margin-top: 15px;
          border-radius: 12px;
        }
        .preview-frame {
          height: 124px;
          border-radius: 12px;
          margin-top: 15px;
          background-color: #F0EEF8;
        }
        .upload-hint {
          font-size: 12px;
          line-height: 14px;
          margin-top: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .upload-void-cheque {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
