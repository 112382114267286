










import Vue from 'vue'

export default Vue.extend({
  props: {
    text: {
      type: String,
      default: 'Upload'
    }
  },
})
